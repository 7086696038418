import React, {Component} from 'react';

class Header extends Component{
    render() {
        return(
            <div id='headerDiv'>
                <header>
                    <ul>
                    <li><img class="headerLogo" src="logo.png" alt="weoptit"></img></li>
                    <li class="textLi"><span class="text">Routing demo</span></li>
                    <li class="textLi"><a href="https://weoptit.com">weoptit.com</a></li>
                    </ul>
                    
                    
                </header>
            </div>
        );
    }
}

export default Header;