import React, {Component} from 'react'
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, Polyline, useMap, Tooltip} from 'react-leaflet';
import {format} from 'react-string-format'

import markerBlueUp from "../assets/marker-blue-up.png"
import markerBlueDown from "../assets/marker-blue-down.png"
import markerRed from "../assets/marker-red.png"
import markerGreen from "../assets/marker-green.png"

var pickMarker = L.icon({
    iconUrl: markerBlueUp,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})

var deliMarker = L.icon({
    iconUrl: markerBlueDown,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})

var startMarker = L.icon({
    iconUrl: markerGreen,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})

var endMarker = L.icon({
    iconUrl: markerRed,
    iconSize: [process.env.REACT_APP_MARKER_W, process.env.REACT_APP_MARKER_H],
    iconAnchor: [12.5, 41],
    popupAnchor: [0, -41]
})


function AutoZoom(data){
    const map = useMap()
    var points = data["points"]
    if(points.length){
        var markers = []
        for(var i=0; i<points.length; i++){
            var p = points[i]
            markers.push([p.Lat,p.Lon])
        }
        map.fitBounds(markers)
    }
    return null
}


function Legend(){
    var map = useMap()
    if(!map.customControl){
        var legend = L.control({ position: "bottomleft" })
        legend.onAdd = () => {
            map.customControl = true
            var div = L.DomUtil.create("div", "legend")
            div.innerHTML += '<img src="marker-green.png" alt="Start marker"></img>'
            div.innerHTML += '<span>Vehicle start</span></br>'
            div.innerHTML += '<img src="marker-red.png" alt="End marker"></img>'
            div.innerHTML += '<span>Vehicle end</span></br>'
            div.innerHTML += '<img src="marker-blue-up.png" alt="Pickup marker"></img>'
            div.innerHTML += '<span>Order pickup</span></br>'
            div.innerHTML += '<img src="marker-blue-down.png" alt="Delivery marker"></img>'
            div.innerHTML += '<span>Order delivery</span>'
            return div
        }
        legend.addTo(map)
    }
    return null
}

class LeafletMap extends Component {
    state = {
        routes: {
            "Statistics": {
                "TotalKilometers": 0,
                "TotalDrivingTimeHours": 0,
                "TotalWorkinTimeHours": 0
            },
            "Routes": [
                //{
                //    "RouteId": "",
                //    "VehicleId": "",
                //    "Route": []
                //}
            ],
            "UnassignedOrders": []
        },
        position: [0.0, 0.0]
    }

    ordersObj = []
    paths = []
    allPoints = []

    constructor(props) {
        super(props);
        this.setState(state => ({routes: props[0].optimizeddata}))
        if(this.props.optimizeddata.Routes.length > 0){
            this.props.optimizeddata.Routes[0].Route.map(data => (this.ordersObj.push({
                id: data.OrderId,
                eventType: data.EventType,
                pos: [data.LocationLatitude, data.LocationLongitude]
            })))
        }
    }

    async componentWillReceiveProps(nextProps) {
        //console.log("componentWillReceiveProps")
        //console.log(nextProps)
        
        this.ordersObj = []
        this.paths = []
        this.allPoints = []
        
        // when submitted
        if(nextProps.isSubmit && nextProps.optimizeddata.Routes){
            this.setState(state => ({routes: nextProps.optimizeddata}))
            for(var i = 0; i < nextProps.optimizeddata.Routes.length; i++){
                var ordersObjTemp = []
                nextProps.optimizeddata.Routes[i].Route.map(data => (ordersObjTemp.push({
                    id: data.OrderId,
                    eventType: data.EventType,
                    pos: [data.LocationLatitude, data.LocationLongitude]
                })))
                this.ordersObj.push(ordersObjTemp)

                var vehicleId = nextProps.optimizeddata.Routes[i]["VehicleId"]
                var vehicles = nextProps.vehicles
                var color = "gray"
                for(var v=0; v<vehicles.length; v++){
                    if(vehicleId == vehicles[v]["Id"]){
                        color = vehicles[v]["Color"]
                        break
                    }
                }

                if(nextProps.accurateRoutes.length){
                    var pathsTemp = []
                    var accurateRoutes = nextProps.accurateRoutes[i]

                    for(var j = 0; j < accurateRoutes.length; j++){
                        pathsTemp.push(accurateRoutes[j])
                        this.allPoints.push(accurateRoutes[j])
                    }
                    this.paths.push({"paths": pathsTemp, "color": color})
                }
            }
        // when template loaded or order/vehicle removed
        }else if(!nextProps.isSubmit && !nextProps.optimizeddata.Routes){
            var ordersObjTemp = []
            for(var i = 0; i < nextProps.optimizeddata.length; i++){
                var order = nextProps.optimizeddata[i]
                ordersObjTemp.push({
                    id: order.Id,
                    eventType: "pickup",
                    pos: [order.PickupLocationLat, order.PickupLocationLong]
                })
                this.allPoints.push([order.PickupLocationLat, order.PickupLocationLong])
                
                ordersObjTemp.push({
                    id: order.Id,
                    eventType: "delivery",
                    pos: [order.DeliveryLocationLat, order.DeliveryLocationLong]
                })
                this.allPoints.push([order.DeliveryLocationLat, order.DeliveryLocationLong])
            }
            
            for(var i = 0; i < nextProps.vehicles.length; i++){
                var veh = nextProps.vehicles[i]
                ordersObjTemp.push({
                    id: veh.Id,
                    eventType: "Vehicle start",
                    pos: [veh.StartLocationLat, veh.StartLocationLong]
                })
                this.allPoints.push([veh.StartLocationLat, veh.StartLocationLong])
                
                ordersObjTemp.push({
                    id: veh.Id,
                    eventType: "Vehicle end",
                    pos: [veh.EndLocationLat, veh.EndLocationLong]
                })
                this.allPoints.push([veh.EndLocationLat, veh.EndLocationLong])
            }
            this.ordersObj.push(ordersObjTemp)
        }
    }

    draw() {}

    render() {
        var startPoint = []
        var endPoint = []
        var pickPoints = []
        var deliPoints = []
        var lines = this.paths
        var allPoints = []

        //console.log("lines")
        //console.log(lines)

        var orders = this.ordersObj
        for(var r = 0; r < orders.length; r++){
            for(var i = 0; i < orders[r].length; i++){
                if(orders[r][i].eventType == "Vehicle start"){
                    startPoint.push(orders[r][i])
                }else if(orders[r][i].eventType == "Vehicle end"){
                    endPoint.push(orders[r][i])
                }else if(orders[r][i].eventType == "pickup"){
                    pickPoints.push(orders[r][i])
                }else if(orders[r][i].eventType == "delivery"){
                    deliPoints.push(orders[r][i])
                }
                allPoints.push({Lat: orders[r][i].pos[0], Lon: orders[r][i].pos[1]})
            }
        }

        var tempAllPoints = this.allPoints
        for(var i=0; i<tempAllPoints.length; i++){
            allPoints.push({Lat:tempAllPoints[0], Lon:tempAllPoints[1]})
        }
        
        var mapPosition = process.env.REACT_APP_DEFAULT_COORDS.split(",").map(x=>+x)
        var defaultMapZoom = process.env.REACT_APP_DEFAULT_ZOOM
        var mapZoom = defaultMapZoom
        if(allPoints.length){
            mapZoom = process.env.REACT_APP_DEFAULT_ZOOMED
        }

        var attr = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        var url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        return (
            <MapContainer className='map'
                          center={mapPosition}
                          zoom={mapZoom}
                          minZoom={6}
                          maxZoom={17}
                          zoomSnap={0.5}
                          scrollWheelZoom={true}
                          attributionControl={false}
                          ref={this.mapInit}>

                <AutoZoom points={allPoints} />

                <TileLayer attribution={attr} url={url} />{
                    pickPoints.map((order) =>
                        <Marker key={order.id + order.eventType}
                                position={order.pos}
                                icon={pickMarker}>
                            <Tooltip>
                                {order.eventType + ": " + order.id}
                            </Tooltip>
                        </Marker>)
                }

                <TileLayer attribution={attr} url={url} />{
                    deliPoints.map((order) =>
                        <Marker key={order.id + order.eventType}
                                position={order.pos}
                                icon={deliMarker}>
                            <Tooltip>
                                {order.eventType + ": " + order.id}
                            </Tooltip>
                        </Marker>)
                }

                <TileLayer attribution={attr} url={url} />{
                    startPoint.map((order) =>
                        <Marker key={order.id + order.eventType}
                                position={order.pos}
                                icon={startMarker}>
                            <Tooltip>
                                {order.eventType + ": " + order.id}
                            </Tooltip>
                        </Marker>)
                }

                <TileLayer attribution={attr} url={url} />{
                    endPoint.map((order) =>
                        <Marker key={order.id + order.eventType}
                                position={order.pos}
                                icon={endMarker}>
                            <Tooltip>
                                {order.eventType + ": " + order.id}
                            </Tooltip>
                        </Marker>)
                }

                <TileLayer attribution={attr} url={url} />{
                    lines.map((line) => <Polyline positions={line.paths} color={line.color} />)
                }

                <Legend />

            </MapContainer>
        )
    }
}

export default LeafletMap;
