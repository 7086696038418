import React, {Component} from 'react';

import ResizePanel from "react-resize-panel";

// components
import Header from './components/header'
import LeafletMap from './components/leafletmap';
import Form from './components/form';

// styles
import './assets/css/basestyle.css'


class App extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        routes: {
            "Statistics": {
                "TotalKilometers": "",
                "TotalDrivingTimeHours": "",
                "TotalWorkinTimeHours": ""
            },
            "Routes": [
                //{
                //    "RouteId": 0,
                //    "VehicleId": "aa",
                //    "Route": []
                //}
            ],
            "UnassignedOrders": []
        },
        accurateRoutes: [],
        vehicles: [],
        isStart: true,
        isSubmit: false // used to draw lines only when Submit
    }

    hideStartbox() {
        this.setState({isStart: false})
    }

    updateOrders = (data, isOnSubmit) => {
        this.setState({isSubmit: isOnSubmit})
        this.setState({routes: data})
    }

    updateAccurateRoutes = (data) => {
        this.setState({accurateRoutes: data})
    }

    updateVehicles = (data) => {
        this.setState({vehicles: data})
    }

    render() {
        return (
            <div id='app'>
                <Header id='header'/>
                <div id="content">
                <div id='input'>
                    <div id='inputItems'>
                        <Form updateOrders={this.updateOrders}
                            updateAccurateRoutes={this.updateAccurateRoutes}
                            updateVehicles={this.updateVehicles} />
                    </div>
                </div>
                <div id='map'>
                    <div id='mapItem'>
                        <LeafletMap optimizeddata={this.state.routes}
                                    accurateRoutes={this.state.accurateRoutes}
                                    vehicles={this.state.vehicles}
                                    isSubmit={this.state.isSubmit}/>
                    </div>
                </div>
                </div>
                
                {this.state.isStart &&
                <div className="startBox">
                    <div className="textbox">
                        <p>Welcome to our 'lite' route optimization demo.<br/>
                        Please select one of the pre-defined demos and once loaded please click the 'optimize' button.</p>
                        <button onClick={(e) => this.hideStartbox()}>Continue</button>
                    </div>
                </div>
                }
            </div>
            
        )
    }
}

export default App;
