import React, {Component} from 'react'
import {format} from 'react-string-format'
import templates from "../assets/templates.json"
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Scrollbar } from "react-scrollbars-custom";

function timeToSeconds(t){
    if(t.length == 0){return ""}
    var splitted = t.split(":")
    return (parseInt(splitted[0])*3600 + parseInt(splitted[1])*60).toString()
}

function hoursToSeconds(t){
    if(t.length == 0){return ""}
    return (parseInt(t)*3600).toString()
}

function minsToSeconds(t){
    if(t.length == 0){return ""}
    return (parseInt(t)*60).toString()
}

function secondsToMins(t){
    if(t.length == 0){return ""}
    var m = Math.floor(parseInt(t)/60)
    return m.toString()
}

function secondsToTime(t){
    var s = parseInt(t)
    var h = Math.floor( s/3600 )
    var m = Math.floor( (s - h*3600)/60 )
    var ms = m.toString()
    if(m<10){ms = "0"+ms}
    return h.toString()+":"+ms
}

function getColor(vehicles){
    var len = vehicles.length
    var colors = process.env.REACT_APP_ROUTE_COLORS.split(",")
    return colors[len % colors.length]
}

const Divider = ({ children }) => {
    return (
      <div className="container">
        <div className="border" />
        <span className="content">
          {children}
        </span>
        <div className="border" />
      </div>
    );
  };

export default class Form extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        orders: [],
        vehicles: [],
        routes: {
            "Statistics": {},
            "Routes": [
            //    {
            //        "RouteId": 0,
            //        "VehicleId": "aa",
            //        "Route": []
            //    }
            ],
            "UnassignedOrders": []
        },
        errorMessage: '',
        isLoading: false,
        demoName: '',
        orderAddresses: [],
        vehicleAddresses: []
    }

    async onTemplate(e, demoname) {
        this.setState({isLoading: true})
        this.setState({demoName: demoname})
        this.setState({orderAddresses: []})
        this.setState({vehicleAddresses: []})
        this.setState({routes: {
            "Statistics": {},
            "Routes": [],
            "UnassignedOrders": []}
        })
        
        if(templates[e]){
            this.state.vehicles = []
            this.state.orders = []
            this.props.updateAccurateRoutes([])
            if(templates[e]["vehicles"]){
                var vehicles = templates[e]["vehicles"]
                for(var i=0; i<vehicles.length; i++){
                    var v = vehicles[i]
                    var addColor = getColor(this.state.vehicles)
                    var newVehicle = {
                        "Id": v["Id"],
                        "StartAddress": v["StartAddress"],
                        "EndAddress": v["EndAddress"],
                        "VehicleTypeId": v["VehicleTypeId"],
                        "VehicleCapacity1": v["VehicleCapacity1"],
                        "VehicleCapacity2": v["VehicleCapacity2"],
                        "VehicleCapacity3": v["VehicleCapacity3"],
                        "Hour_cost": v["Hour_cost"],
                        "Km_cost": v["Km_cost"],
                        "MaxWorkingDuration": v["MaxWorkingDuration"],
                        "MaxDrivingTime": v["MaxDrivingTime"],
                        "StartTime": v["StartTime"],
                        "EndTime": v["EndTime"],
                        "StartLocationLat": v["StartLocationLat"],
                        "StartLocationLong": v["StartLocationLong"],
                        "EndLocationLat": v["EndLocationLat"],
                        "EndLocationLong": v["EndLocationLong"],
                        "Color": addColor
                    }
                    
                    this.setState(prevState => ({
                        vehicleAddresses: [...prevState.vehicleAddresses, {
                            'id': v["Id"],
                            'startAddress': v["StartAddress"],
                            'endAddress': v["EndAddress"]
                        }]
                    }))
                    await this.setState((prevState) => ({vehicles: [...prevState.vehicles, Object.assign({}, newVehicle)]}))
                    this.props.updateVehicles(this.state.vehicles) //needed for route colors
                }
                
                var orders = templates[e]["orders"]
                for(var i=0; i<orders.length; i++){
                    var o = orders[i]
                    var newOrder = {
                        "Id": o["Id"],
                        "PickupAddress": o["PickupAddress"],
                        "DeliveryAddress": o["DeliveryAddress"],
                        "PickupLocationLat": o["PickupLocationLat"],
                        "PickupLocationLong": o["PickupLocationLong"],
                        "DeliveryLocationLat": o["DeliveryLocationLat"],
                        "DeliveryLocationLong": o["DeliveryLocationLong"],
                        "RequiredCapacity1": o["RequiredCapacity1"],
                        "RequiredCapacity2": o["RequiredCapacity2"],
                        "RequiredCapacity3": o["RequiredCapacity3"],
                        "PickupServiceTime": o["PickupServiceTime"],
                        "DeliveryServiceTime": o["DeliveryServiceTime"],
                        "PickupTWStart": o["PickupTWStart"],
                        "PickupTWEnd": o["PickupTWEnd"],
                        "DeliveryTWStart": o["DeliveryTWStart"],
                        "DeliveryTWEnd": o["DeliveryTWEnd"]
                    }
                    
                    this.setState(prevState => ({
                        orderAddresses: [...prevState.orderAddresses, {
                            'id': o["Id"],
                            'pickupAddress': o["PickupAddress"],
                            'deliveryAddress': o["DeliveryAddress"]
                        }]
                    }))
                    await this.setState((prevState) => ({orders: [...prevState.orders, Object.assign({}, newOrder)]}))
                }
            }
        }
        this.props.updateOrders(this.state.orders, false)
        this.setState({isLoading: false})
    }

    async onSubmit() {
        this.setState({isLoading: true})
        this.setState({routes: {
            "Statistics": {},
            "Routes": [],
            "UnassignedOrders": []}
        })
        
        const requestBody = {'orders': this.state.orders, 'vehicles': this.state.vehicles}
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "x-api-key": "f26f5b6c-29a3-4017-91db-f254b0d02413",
                "Host": "routing.weoptit.com"
            },
            body: JSON.stringify(requestBody)
        };
        requestOptions['headers']['Content-Length'] = 1782
        var optimized = null
        await fetch('https://routing.weoptit.com/api/vehiclerouting', requestOptions)
            .then(response => response.json()
                .then((data) => {
                    this.setState({responseValue: data})
                    this.setState({routes: data})
                    this.props.updateOrders(data, true)
                    optimized = data;
                }))
        
        //console.log("optimized")
        //console.log(optimized)
        var optimizedRoutes = []
        if(optimized){
            var OSRM_ADDR = process.env.REACT_APP_OSRM_API + '/route/v1/driving/'
            for(var r = 0; r < optimized['Routes'].length; r++){
                var osrm_addr_temp = OSRM_ADDR
                var optimizedRoutesTemp1 = optimized['Routes'][r]['Route']
                for(var i = 0; i < optimizedRoutesTemp1.length; i++){
                    if(i > 0){ osrm_addr_temp += ';' }
                    var ordobj = optimizedRoutesTemp1[i]
                    osrm_addr_temp += ordobj['LocationLongitude'] + "," + ordobj['LocationLatitude']
                }
                osrm_addr_temp += '?overview=simplified&geometries=geojson'
                
                var response = await fetch(format(osrm_addr_temp))
                await response.json().then((data) => {
                    if(data['code'] == "Ok"){
                        var optimizedRoutesTemp2 = []
                        var coords = data['routes'][0]['geometry']['coordinates']
                        for(var i = 0; i < coords.length; i++){
                            optimizedRoutesTemp2.push([coords[i][1], coords[i][0]])
                        }
                        optimizedRoutes.push(optimizedRoutesTemp2)
                    }
                })
            }
        }
        this.props.updateAccurateRoutes(optimizedRoutes)
        this.setState({isLoading: false})
    }

    async removeOrder(e){
        this.setState({errorMessage: ''})
        this.setState({routes: {
            "Statistics": {},
            "Routes": [],
            "UnassignedOrders": []}
        })
        
        await this.setState({
            orders: this.state.orders.filter(function (order) {
                return order.Id !== e.target.value
            })
        })
        this.props.updateOrders(this.state.orders, false)
    }

    async removeVehicle(e){
        this.setState({errorMessage: ''})
        this.setState({routes: {
            "Statistics": {},
            "Routes": [],
            "UnassignedOrders": []}
        })
        
        await this.setState({
            vehicles: this.state.vehicles.filter(function (vehicle) {
                return vehicle.Id !== e.target.value
            })
        })
        this.props.updateOrders(this.state.orders, false)
        this.props.updateVehicles(this.state.vehicles)
    }

    translateWaypointToAddress(vehicleId, orderId, eventType) {
        var addressData = ''
        if (orderId === '-') {
            var vehicleData = this.state.vehicleAddresses.filter(vehicle => vehicle.id === vehicleId)[0]
            if (eventType === 'Vehicle Start') {
                addressData = vehicleData.startAddress
            } else {
                addressData = vehicleData.endAddress
            }
            return addressData
        } else {
            var orderData = this.state.orderAddresses.filter(order => order.id === orderId)[0]
            if (eventType === 'pickup') {
                addressData = orderData.pickupAddress
            } else {
                addressData = orderData.deliveryAddress
            }
            return addressData
        }
    }

    render() {
        var routes = this.state.routes.Routes
        console.log(routes)
        var waypointCount = 0
        for(var i=0; i<routes.length; i++){
            waypointCount += routes[i].Route.length
        }
        return (
            <div id='formAndDataDiv'>
                <div id='formDiv'>
                    <div id='templatesDiv'>
                        <div id='template1Div'>
                        <div id="mainCarousel">
                            <AwesomeSlider>
                            <div id="slide">
                            <h1>Demo 1</h1>
                            <p>Simple demo with two separate routes.</p>
                            <button id="template1" onClick={(e) => this.onTemplate(e.target.id, "DEMO 1")}>Select</button>
                            </div>
                            <div id="slide">
                            <h1>Demo 2</h1>
                            <p>Simple full day route with one vehicle.</p>
                            <button id="template2" onClick={(e) => this.onTemplate(e.target.id, "DEMO 2")}>Select</button>
                            </div>
                            <div id="slide">
                            <h1>Demo 3</h1>
                            <p>Another full day route. All pickups from terminal.</p>
                            <button id="template3" onClick={(e) => this.onTemplate(e.target.id, "DEMO 3")}>Select</button>
                            </div>
                            <div id="slide">
                            <h1>Demo 4</h1>
                            <p>A more complex case with multiple vehicles and<br/>
                            routes, with some vehicles being used for a full day <br/> and others split into shifts.</p>
                            <p><span className="tooltip"><img className="qicon" src="question.png" alt="Q"></img>
                                <span className="tooltiptext">
                                (In demo mode the loading time is 5 seconds and optimization can take about 90 seconds.)
                                </span></span></p>
                            <button id="template4" onClick={(e) => this.onTemplate(e.target.id, "DEMO 4")}>Select</button>
                            </div>
                            </AwesomeSlider>
                            
                        </div>
                        </div>
                    </div>

                    <div id='demoName'>
                    <Divider>{this.state.demoName && "" + this.state.demoName}</Divider>
                    </div>

                    <div id='submitButtonDiv'>
                        {this.state.isLoading &&
                            <span class="mr40"></span>
                        }
                        <button onClick={() => this.onSubmit()}>
                            Optimize
                        </button>
                        {this.state.isLoading &&
                            <span><img className="loading" src="loading.gif" alt="loading"></img></span>
                        }
                    </div>
                </div>

                <div id='routeStatistics'>
                    <div id='distanceStat'>
                        Drive distance:
                        {this.state.routes.Statistics.TotalKilometers && ' ' + this.state.routes.Statistics.TotalKilometers + ' km'}
                        {!this.state.routes.Statistics.TotalKilometers  && ' -'}
                    </div>
                    <div id='driveStat'>
                        Drive time:
                        {this.state.routes.Statistics.TotalDrivingTimeHours && ' ' + this.state.routes.Statistics.TotalDrivingTimeHours + ' h'}
                        {!this.state.routes.Statistics.TotalDrivingTimeHours && ' -'}
                    </div>
                    <div id='workStat'>
                        Work hours:
                        {this.state.routes.Statistics.TotalWorkinTimeHours && ' ' + this.state.routes.Statistics.TotalWorkinTimeHours + " h"}
                        {!this.state.routes.Statistics.TotalWorkinTimeHours && ' -'}
                    </div>
                    <div id='unassignedStat'>
                        {'Unassigned orders: ' + this.state.routes.UnassignedOrders.length}
                    </div>
                </div>

                <div id='dataDiv'>
                    <div id='ordersTitleDiv'>
                        <p className="boxtitle"><span>Orders: {this.state.orders.length}</span></p>
                    </div>
                    <div id='vehiclesTitleDiv'>
                        <p className="boxtitle"><span>Vehicles: {this.state.vehicles.length}</span></p>
                    </div>
                    <div id='waypointsTitleDiv'>
                        <p className="boxtitle"><span>Waypoints: {waypointCount}</span></p>
                    </div>
                    
                    <div id='ordersListingDiv'>
                    <Scrollbar>
                        {this.state.orders.map((order) => <p key={order.Id} className='textField'>
                        <span className="bold">{order.Id}</span><br/>
                        RequiredCapacity: {order.RequiredCapacity1}/{order.RequiredCapacity2}/{order.RequiredCapacity3}<br/>
                        ServiceTime: {secondsToMins(order.PickupServiceTime)}/{secondsToMins(order.DeliveryServiceTime)}<br/>
                        Pickup: {order.PickupAddress} [{secondsToTime(order.PickupTWStart)}-{secondsToTime(order.PickupTWEnd)}]<br/>
                        Delivery: {order.DeliveryAddress} [{secondsToTime(order.DeliveryTWStart)}-{secondsToTime(order.DeliveryTWEnd)}]<br/>
                        {this.state.orders.length > 1 &&
                            <button value={order.Id} onClick={(e) => this.removeOrder(e)}>Remove</button>
                        }
                        </p>)}
                    </Scrollbar>
                    </div>
                    
                    <div id='vehiclesDiv'>
                        <div id='vehiclesListingDiv'>
                        <Scrollbar>
                            {this.state.vehicles.map((vehicle) => <p className='textField'><span className="colorbox" style={{background: vehicle.Color}}>______</span>
                            <span className="pl10 bold">{vehicle.Id}</span><br/>
                            Capacity: {vehicle.VehicleCapacity1}/{vehicle.VehicleCapacity2}/{vehicle.VehicleCapacity3}<br/>
                            Km-cost: {vehicle.Km_cost}
                            <span class="pl15"></span>
                            H-cost: {vehicle.Hour_cost}<br/>
                            Start: {vehicle.StartAddress} [{secondsToTime(vehicle.StartTime)}-{secondsToTime(vehicle.EndTime)}]<br/>
                            End: {vehicle.EndAddress}<br/>
                            {this.state.vehicles.length > 1 &&
                                <button value={vehicle.Id} onClick={(e) => this.removeVehicle(e)}>Remove</button>
                            }
                            </p>)}
                        </Scrollbar>
                        </div>
                    </div>

                    <div id='waypoints'>
                    <Scrollbar>
                        <div id='waypointsListingDiv'>
                            {
                                this.state.routes.Routes.map((route) => {
                                    return (
                                        <div>
                                            {route.Route.map(r => {
                                                return (
                                                    <p className='textField'>
                                                        Waypoint: {r.OrderId + " " + r.EventType} <br/>
                                                        Address: {this.translateWaypointToAddress(route.VehicleId, r.OrderId, r.EventType)}
                                                        <br/>
                                                        Vehicle: {route.VehicleId}
                                                    </p>
                                                )
                                            })}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Scrollbar>
                    </div>
                </div>

            </div>
        )
    }
}